import { AvatarGroup, Stack } from '@mui/material';
import { IoChatbubblesOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const DataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .data-table {
    overflow: auto;

    .row {
      align-items: center;
      column-gap: 0.75%;
      padding: 0.5rem;
    }
    .row:not(last-child) {
      margin-bottom: 0.5rem;
    }
    .row:not(first-child) {
      margin-top: 0.5rem;
    }
  }
`;

export const Row = styled.div<{ backlog?: boolean; headerRow?: boolean; hover?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.75%;
  padding: 0.5rem;
  &:not(last-child) {
    margin-bottom: 0.5rem;
  }
  &:not(first-child) {
    margin-top: 0.5rem;
  }
  ${({ backlog }) =>
    backlog &&
    `
    opacity: ${backlog ? '50%' : '100%'};
    cursor: pointer;
    `}
  ${({ hover }) =>
    hover &&
    `&:hover {
    background-color: #80808012;
    border-radius: 0.625rem;
    cursor: pointer;
  }`}
  ${({ headerRow }) =>
    headerRow &&
    `
    height:3rem;
    padding-right: 14px;
    `}
`;

export const AvatarContainer = styled(AvatarGroup)`
  justify-content: flex-end;
`;

export const ChatBubbleIcon = styled(IoChatbubblesOutline)<{ active: boolean }>`
  ${({ active }) =>
    !active &&
    `
    opacity: 0.5;
`}
`;

export const CustomStack = styled(Stack)`
  align-items: center;
`;

export const NoDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;
