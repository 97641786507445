import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { MdCheckCircle } from 'react-icons/md';

import { Logo } from '../logo/logo';

import { createPassword, resetPassword, submitLogin, updatePassword } from '../../reducers/account.reducer';
import { setErrors } from '../../reducers/app.reducer';

import { Button } from '../Button';
import { Modal } from '../../components/modal/Modal';
import { InputComponent } from '../form/InputComponent';

import { COLORS } from '../../utils/constants';
import { getAccountByEmail, isvalidToken } from '../../utils/api';
import { addEventTracking } from '../../utils/utils';
import { Loader } from '@components-new/atoms';

interface PasswordChecker {
  eightCharsOrGreater?: string;
  uppercase?: string;
  lowercase?: string;
  number?: string;
  specialChar?: string;
}

const Login = ({ mode }) => {
  const dispatch = useDispatch<any>();

  const [loginMode, setLoginMode] = useState(mode || 'connection');
  const [userId, setUserId] = useState('');
  const [userToken, setUserToken] = useState('');
  const [companyName, setCompanyName] = useState('');
  // const [shareId, setShareId] = useState(null);

  const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/g;
  const atLeastOneLowercase = /[a-z]/g;
  const atLeastOneUppercase = /[A-Z]/g;
  const atLeastOneNumeric = /[0-9]/g;
  const atLeastOneSpecialChar = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  const eightCharsOrMore = /.{8,}/g;
  const [modal, setModal] = useState({ show: false, title: '', content: '' });
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordTracker, setPasswordTracker] = useState<PasswordChecker>({});
  const [newPassword, setNewPassword] = useState({ newPassword: '', confirmPassword: '' });
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [isNameFulfilled, setIsNameFulfilled] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [resetEnabled, setResetEnabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [timeBeforeNextTry, setTimeBeforeNextTry] = useState(0)

  useEffect(() => {
    let params = new URL(window.location.href).searchParams;
    if (params.get('uid') && params.get('uto')) {
      setLoginMode(params.get('mode'));
      setUserId(params.get('uid'));
      setUserToken(params.get('uto'));
      setCompanyName(params.get('cna'));
      // setShareId(params.get('sid'));
      // setDrive(params.get('drive') === 'true');
    }
  }, [dispatch, setLoginMode]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const getCredentials = (value: string) => {
    return credentials[value] ?? '';
  };

  const getNewPassword = (value: string) => {
    return newPassword[value];
  };

  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      switch (loginMode) {
        case 'passwordCreation':
        case 'passwordUpdate':
        case 'share':
          await handlePasswordChange();
          break;
        case 'passwordReset':
          await handleResetPassword();
          break;
        case 'connection':
          await handleLogin();
          break;
      }
    }
  };

  const handleResetChange = e => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  const handleUserNameChange = e => {
    e.target.name === 'nom' ? setNom(e.target.value) : setPrenom(e.target.value);
    let newNameFulfilled = e.target.name === 'nom' ? e.target.value !== '' && prenom !== '' : nom !== '' && e.target.value !== '';
    setIsNameFulfilled(newNameFulfilled);
  };

  const handleCredentialChange = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    dispatch(submitLogin({ email: getCredentials('email'), password: getCredentials('password') }));
  };

  const handlePasswordChange = async () => {
    if (getNewPassword('newPassword') === getNewPassword('confirmPassword')) {
      if (passwordIsValid) {
        if (loginMode === 'passwordUpdate' || loginMode === 'passwordCreation') {
          await dispatch(updatePassword({ userId, userToken, password: getNewPassword('newPassword') }));
        } else {
          await dispatch(
            createPassword({
              userId,
              userToken,
              password: getNewPassword('newPassword'),
              userName: nom,
              firstName: prenom,
            })
          );
        }
        setModal({
          show: true,
          title: loginMode === 'passwordUpdate' ? 'Changement du mot de passe' : 'Création de compte',
          content: loginMode === 'passwordUpdate' ? 'Votre mot de passe a bien été modifié' : 'Votre compte a bien été créé',
        });
        setLoginMode('connection');
      }
    }
  };

  const handleResetPassword = async () => {
    let account = await getAccountByEmail(credentials.email)
    if(account && account.length){
      if(!account[0].isArchived){
        setResetEnabled(false)
        setLoading(true)
        addEventTracking('Password', 'Reset');
        await dispatch(resetPassword({ email: credentials.email }));
        setModal({
          show: true,
          title: 'Réinitialisation du mot de passe',
          content:
            'Votre demande a bien été prise en compte.<br>Si vous possédez un compte, un mail vous a été envoyé avec les instructions de réinitialisation.',
        });
        setLoading(false)
        setTimeBeforeNextTry(30000);
      } else {
        setModal({
          show: true,
          title: 'Compte archivé',
          content:
            'Ce compte n\'est plus disponible.<br><br>Il n\'est pas possible de s\'y connecter',
        });
      }
    }
    else {
      setModal({
        show: true,
        title: 'Compte inexistant',
        content:
          'Cet email n\'est lié à aucun compte',
      });
    }
  }

  useEffect(() => {
    if (timeBeforeNextTry <= 0) return;
  
    const timerId = setInterval(() => {
      setTimeBeforeNextTry((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timerId);
          setResetEnabled(true);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);
  
    return () => clearInterval(timerId);
  }, [timeBeforeNextTry]);

  const getProps = loginMode => {
    switch (loginMode) {
      case 'passwordCreation':
        return {
          title: 'Créer votre mot de passe ',
          buttonLabel: 'Changer le mot de passe',
          buttonFn: handlePasswordChange,
          enabled: passwordIsValid && getNewPassword('newPassword') === getNewPassword('confirmPassword'),
        };
      case 'passwordUpdate':
        return {
          title: 'Modifier votre mot de passe ',
          buttonLabel: 'Changer le mot de passe',
          buttonFn: handlePasswordChange,
          enabled: passwordIsValid && getNewPassword('newPassword') === getNewPassword('confirmPassword'),
        };
      case 'share':
        return {
          title: `Vous êtes rattaché au compte de ${companyName}`,
          buttonLabel: 'Créer mon compte',
          buttonFn: handlePasswordChange,
          enabled: passwordIsValid && getNewPassword('newPassword') === getNewPassword('confirmPassword') && isNameFulfilled,
        };
      case 'passwordReset':
        return {
          title: 'Réinitialiser le mot de passe',
          buttonLabel: 'Recevoir les instructions de réinitialisation',
          buttonFn: handleResetPassword,
          enabled: resetEnabled,
        };
      case 'connection':
        return {
          title: 'Connexion',
          buttonLabel: 'Se connecter',
          buttonFn: handleLogin,
          enabled: true,
        };
    }
  };

  useEffect(() => {
    let passwordTracker = {
      lowercase: getNewPassword('newPassword')?.match(atLeastOneLowercase),
      uppercase: getNewPassword('newPassword')?.match(atLeastOneUppercase),
      number: getNewPassword('newPassword')?.match(atLeastOneNumeric),
      specialChar: getNewPassword('newPassword')?.match(atLeastOneSpecialChar),
      eightCharsOrGreater: getNewPassword('newPassword')?.match(eightCharsOrMore),
    };
    setPasswordTracker(passwordTracker);
    setPasswordIsValid(getNewPassword('newPassword')?.match(PASSWORD_REGEX));
  }, [newPassword]);

  useEffect(() => {
    if (userToken) {
      isvalidToken(userToken).then(res => {
        if (!res.ok) {
          setLoginMode('passwordReset');
          dispatch(
            setErrors({
              title: "Lien d'activation expiré",
              content:
                'Le lien qui vous a été envoyé pour la création de votre mot de passe à expiré, veuillez faire une demande de mot de passe oublié , ou contacter notre support (support@dipeeo.com)',
            })
          );
        }
      });
    }
  }, [userToken]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <GlobalContainer>
      {loading && <Loader></Loader>}
      <Modal onClose={() => setModal({ ...modal, show: false })} size={{ height: undefined, width: '30%' }} show={modal.show} title={modal.title}>
        <ModalContentContainer>
          <ModalContentTextContainer dangerouslySetInnerHTML={{ __html: modal.content }} />
        </ModalContentContainer>
      </Modal>
      <LoginContainer loginMode={loginMode}>
        <StyledLogo whiteBg={true} large={screenSize.width > 1280}></StyledLogo>
        {(loginMode === 'passwordCreation' || loginMode === 'passwordUpdate' || loginMode === 'share') && (
          <ContentContainer loginMode={loginMode}>
            {loginMode === 'share' && (
              <UserNameContainer>
                <InputComponent
                  label={'Nom'}
                  type='text'
                  onChange={handleUserNameChange}
                  name={'nom'}
                  value={nom}
                  sharable={false}
                  required={true}
                  error={!isNameFulfilled && 'Ces champs ne doivent pas être vides'}
                />
                <InputComponent
                  label={'Prénom'}
                  type='text'
                  onChange={handleUserNameChange}
                  name={'firstName'}
                  value={prenom}
                  sharable={false}
                  required={true}
                />
              </UserNameContainer>
            )}
            <PasswordContainer>
              <InputComponent
                label={'Nouveau mot de passe'}
                onChange={handleResetChange}
                type={'password'}
                name={'newPassword'}
                onKeyDown={handleKeyDown}
                value={newPassword.newPassword}
                sharable={false}
              />
            </PasswordContainer>

            <PasswordContainer>
              <InputComponent
                label={'Confirmez le mot de passe'}
                onChange={handleResetChange}
                type={'password'}
                name={'confirmPassword'}
                onKeyDown={handleKeyDown}
                value={newPassword.confirmPassword}
                sharable={false}
                error={
                  newPassword.confirmPassword !== '' && newPassword.newPassword !== newPassword.confirmPassword && 'Le mot de passe ne correspond pas'
                }
              />
            </PasswordContainer>

            <HelperTextContainer passwordTracker={passwordTracker}>
              <HelperTextItem valid={!!passwordTracker?.eightCharsOrGreater}>
                <MdCheckCircle />
                <label>8 caractères</label>
              </HelperTextItem>
              <HelperTextItem valid={!!passwordTracker?.number}>
                <MdCheckCircle />
                <label>1 chiffre</label>
              </HelperTextItem>
              <HelperTextItem valid={!!passwordTracker?.uppercase}>
                <MdCheckCircle />
                <label>1 majuscule</label>
              </HelperTextItem>
              <HelperTextItem valid={!!passwordTracker?.lowercase}>
                <MdCheckCircle />
                <label>1 minuscule</label>
              </HelperTextItem>
              <HelperTextItem valid={!!passwordTracker?.specialChar}>
                <MdCheckCircle />
                <label>1 caractère spécial</label>
              </HelperTextItem>
            </HelperTextContainer>
          </ContentContainer>
        )}
        {(loginMode === 'passwordReset' || loginMode === 'connection') && (
          <ContentContainer loginMode={loginMode}>
            <InputComponent
              label={'Email'}
              onChange={handleCredentialChange}
              type='text'
              name={'email'}
              onKeyDown={handleKeyDown}
              value={credentials.email}
              sharable={false}
              acceptWhiteSpaces={false}
            />
            {loginMode === 'connection' && (
              <PasswordContainer>
                <InputComponent
                  label={'Mot de passe'}
                  onChange={handleCredentialChange}
                  type={'password'}
                  name={'password'}
                  onKeyDown={handleKeyDown}
                  value={credentials.password}
                  sharable={false}
                />
              </PasswordContainer>
            )}
          </ContentContainer>
        )}
        <LinksContainer loginMode={loginMode}>
          <Button label={getProps(loginMode).buttonLabel} onClick={getProps(loginMode).buttonFn} disabled={!getProps(loginMode).enabled} />
          <label onClick={() => setLoginMode(loginMode === 'connection' ? 'passwordReset' : 'connection')}>
            <u>{loginMode === 'connection' ? 'Mot de passe oublié' : 'Retourner vers la page de connexion'}</u>
          </label>
          {timeBeforeNextTry > 0 && <TextBeforeNextTry> Nouvelle demande disponible dans :  {timeBeforeNextTry / 1000} </TextBeforeNextTry>}
          {loginMode === 'connection' && (
            <Disclaimer>
              En continuant, vous acceptez nos{' '}
              <a href={'/CGU Dipeeo app.pdf'} target={'_blank'} rel='noreferrer'>
                conditions générales d’utilisation
              </a>{' '}
              ainsi que nos{' '}
              <a href={'/Dipeeo - FR -Politique_de_confidentialite -app_en_ligne.pdf'} target={'_blank'} rel='noreferrer'>
                politiques de confidentialités
              </a>
              .
            </Disclaimer>
          )}
        </LinksContainer>
      </LoginContainer>
    </GlobalContainer>
  );
};

export default Login;

const GlobalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.NiceBlue};
`;

const LoginContainer = styled.div<{ loginMode: string }>`
  width: 592px;
  max-width: 33%;
  min-height: 0;
  max-height: 65%;
  background-color: white;
  padding: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: ${({ loginMode }) => (loginMode === 'share' ? '20px' : '40px')};
  border-radius: 5px;

  @media (max-width: 1280px) {
    padding: 35px;
    row-gap: 15px;
    max-height: ${({ loginMode }) => (loginMode === 'share' ? 'none' : '65%')};
  }
`;

const ContentContainer = styled.div<{ loginMode: string }>`
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${({ loginMode }) => (loginMode === 'share' ? '15px' : '35px')};

  @media (max-width: 1280px) {
    row-gap: ${({ loginMode }) => (loginMode === 'share' ? '15px' : '20px')};
  }
`;

const HelperTextContainer = styled.div<{ passwordTracker: object }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.16rem;

  @media (max-width: 1540px) {
    font-size: 1rem;
  }
`;

const HelperTextItem = styled.div<{ valid: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  transition: color 0.2s;
  color: ${({ valid }) => (valid ? 'black' : COLORS.lightGrey)};
  margin-top: 7px;

  svg {
    transition: color 0.2s;
    height: 24px;
    width: 24px;
    color: ${({ valid }) => (valid ? '#0ce052' : COLORS.lightGrey)};
  }

  @media (max-width: 1280px) {
    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const LinksContainer = styled.div<{ loginMode: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;

  label {
    cursor: pointer;
    display: ${({ loginMode }) => (loginMode === 'share' ? 'none' : 'flex')};
  }

  @media (max-width: 1280px) {
    row-gap: 10px;
    div {
      font-size: 12px;
    }
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ModalContentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  font-size: 1.2rem;
  text-align: center;
`;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    font-family: 'Baloo 2';
    font-size: 1rem;
  }

  i {
    position: absolute;
    right: 0px;
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledLogo = styled(Logo)``;

const Disclaimer = styled.span`
  font-size: 1rem;
  text-align: center;
`;

const TextBeforeNextTry = styled.span`
  font-size: 1.2rem;
`;
