import { COLORS } from '@utils/constants';
import styled, { css } from 'styled-components';

export const FileExplorerWindow = styled.div`
  height: calc(100% - 30px);
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: relative;
`;
export const BreadcrumbContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const FileSlider = styled.div`
  height: 100%;
`;

export const FileContainer = styled.div`
  display: grid;
  row-gap: 15px;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-items: center;
`;

export const DragOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f595;
  border: 1px dashed #e0e0e0;
  z-index: 99999;
  font-size: 1.5em;
  color: #9e9e9e;
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  row-gap: 20px;
  border: 1px dashed ${COLORS.MediumGrey};
  border-radius: 4px;

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;

    b {
      color: ${COLORS.NiceBlue};
      text-decoration: underline;
    }
  }

  svg {
    color: ${COLORS.Black};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const BreadcrumbItem = styled.div<{ current: boolean }>`
  font-size: 1.125rem;
  color: ${COLORS.Grey};
  font-family: 'Roboto', sans-serif;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  ${({ current }) =>
    current &&
    css`
      font-weight: bold;
      color: ${COLORS.NiceBlue} !important;
    `}
  &:hover {
    opacity: 1;
  }
`;
export const Content = styled.div<{ isLoading?: boolean }>`
  position: relative;
  flex: 1;
  ${({ isLoading }) =>
    isLoading &&
    `
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 100%;
  `};

  > * {
    &:focus-visible {
      outline: none;
    }
  }
`;
export const Link = styled.a``;

export const ExplorerItem = styled.div<{ type?: string; selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      padding: 10px;
    `};

  svg {
    height: 100px;
    width: 100px;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;

    ${({ type }) =>
      type &&
      css`
        color: ${COLORS[type]};
      `}
  }

  > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    position: absolute;
    right: -25px;
    top: 50px;
    height: 100px;
    transition: all 0.2s ease-in-out;

    > svg,
    > a > svg {
      height: 25px;
      width: 25px;
      color: ${COLORS.Grey};
      transition: opacity 0.2s ease-in-out;

      &:hover {
        color: ${COLORS.Black};
      }
    }
  }

  span {
    text-align: center;
    font-size: 1.225rem;
    font-weight: 500;
    color: ${COLORS.Grey};
  }

  &:hover {
    > div > div {
      top: 0;

      > svg,
      > a > svg {
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }

    > svg {
      opacity: 1;
    }
  }
`;

export const FileExplorerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 20px;
  position: relative;
  overflow-y: auto;
`;
