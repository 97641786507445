import React, { useEffect, useRef, useState } from 'react';
import MessageList from './MessageList';
import {
  ChatContainer,
  MessageFooter,
  MessageListContainer,
  InputContainer,
  IconContainer,
  Line,
  UserListGlobalContainer,
  UserRow,
  UserItem,
  UserListSlider,
  UserListContainer,
  CustomDiv, CustomDivContainer,
} from './Chat.styles';
import dayjs from 'dayjs';
import { ReactComponent as Divider} from '@assets/DIVIDER.svg';
import SubmitIcon from '@components/chat/SubmitIcon';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { CommentProps } from '@components-new/common/types';
import { addEventTracking, getUserNameById, stringAvatar } from '@utils/utils';
import { tippy } from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { useSelector } from 'react-redux';
import { selectActiveAccount, selectAllAccounts } from '../../reducers/account.reducer';
import { Avatar } from '@mui/material';
import OnAtIcon from '@components/chat/OnAtIcon';

interface UserProps {
  id: string;
  name: string;
  firstName: string;
}

interface ChatProps {
  isLoading: boolean;
  messages: CommentProps[];
  user: UserProps;
  handleMessage: (message: CommentProps) => Promise<void>;
  updateSeenStatus: () => void;
  deleteMessage: (filtredComment: CommentProps[]) => void;
  admin: boolean;
}

const Chat: React.FC<ChatProps> = ({ messages, isLoading, user, handleMessage, updateSeenStatus, deleteMessage, admin }) => {
  const activeAccount = useSelector(selectActiveAccount);
  const allAccounts = useSelector(selectAllAccounts);
  const { t } = useTranslation();
  const tooltipRef = useRef(null);
  const userListRef = useRef(null);
  const userListSliderRef = useRef(null);
  const userListContainerRef = useRef(null);
  const [sortedMessages, setSortedMessages] = useState<CommentProps[]>([]);
  const [showUserList, setShowUserList] = useState<boolean>(false);
  const [selectedRowState, _setSelectedRowState] = useState<number>(0);
  const [lastCaracter, _setLastCaracter] = useState<string>('');
  const [message, _setMessage] = useState<string>('');
  const [caretPositionState, _setCaretPositionState] = useState<number>(0);
  const [mentionedUsers, _setMentionedUsers] = useState<any>([]);
  const [search, _setSearch] = useState('');
  const [isMentioning, _setIsMentioning] = useState(false);
  const messageListRef = useRef(null);
  const customInputRef = useRef(null);
  const selectedRowRef = useRef(0);

  const selectedRowStateRef = React.useRef(selectedRowState);
  const setSelectedRowState = data => {
    selectedRowStateRef.current = data;
    _setSelectedRowState(data);
  };
  const lastCaracterRef = React.useRef(lastCaracter);
  const setLastCaracter = data => {
    lastCaracterRef.current = data;
    _setLastCaracter(data);
  };

  const messageRef = React.useRef(message);
  const setMessage = data => {
    messageRef.current = data;
    _setMessage(data);
  };

  const caretPositionStateRef = React.useRef(caretPositionState);
  const setCaretPositionState = data => {
    caretPositionStateRef.current = data;
    _setCaretPositionState(data);
  };

  const mentionedUsersRef = React.useRef(mentionedUsers);
  const setMentionedUsers = data => {
    mentionedUsersRef.current = data;
    _setMentionedUsers(data);
  };

  const searchRef = React.useRef(search);
  const setSearch = data => {
    searchRef.current = data;
    _setSearch(data);
  };

  const isMentioningRef = React.useRef(isMentioning);
  const setIsMentioning = data => {
    isMentioningRef.current = data;
    _setIsMentioning(data);
  };

  const customInput = document.querySelector('#customInput');

  const normalizeString = str =>
    str
      ?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  const getFilteredUsers = () => {
    const normalizedSearch = normalizeString(searchRef.current || '');
    return activeAccount?.users
      .filter(user => {
        const normalizedFirstName = normalizeString(user?.firstName || '');
        const normalizedEmail = normalizeString(user?.email || '');
        return (
          normalizedFirstName.includes(normalizedSearch) ||
          normalizedEmail.includes(normalizedSearch)
        );
      });
  };

  const handleSubmit = async event => {
    addEventTracking('Chat', 'Add message');
    event.preventDefault();
    const newMessage: CommentProps = {
      _id: uuidv4(),
      text: message !== '' ? message : event.target.innerText,
      time: new Date(),
      seenComment: [
        {
          seen: true,
          userID: user.id,
        },
      ],
      sender: {
        name: user.name,
        id: user.id,
      },
      mentionedUsers: mentionedUsersRef.current,
    };
    setSortedMessages((prev) => [...prev, newMessage]);
    await handleMessage(newMessage);

    const customInputElement = customInputRef.current;
    if (customInputElement) {
      customInputElement.innerHTML = '';
    }
    setMessage('');
    setMentionedUsers([]);
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const handleDeleteMessage = (commentID: string | number) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) {
      addEventTracking('Chat', 'Delete message');
      const filteredComments = sortedMessages.filter(comment => comment._id !== commentID);
      deleteMessage(filteredComments);
    }
  };

  const handleSelectUser = (user, caretPosition, isLegalOfficer) => {
    let newMessage = messageRef.current;
    let newCaretPosition = caretPosition;
    if(searchRef.current){
      const searchLength = searchRef.current.length;
      newMessage = newMessage.slice(0, caretPosition - searchLength).trim() +
        newMessage.slice(caretPosition).trim();
      newCaretPosition -= searchLength;
      setSearch(null)
    }
    if (!mentionedUsersRef.current.some(mentionedUser => mentionedUser.userId === user._id)) {
      setMentionedUsers([...mentionedUsersRef.current, { userId: user._id, isLegalOfficer }]);
    }
    let mentionedUserName = undefined;
    if(user.firstName && user.firstName !== ''){
      mentionedUserName = user.firstName;
    } else if(user.name && user.name !== ''){
      mentionedUserName = user.name
    } else {
      mentionedUserName = user.email.split("@")[0];
    }

    newMessage = newMessage + mentionedUserName;
    let targetInput = customInput ? customInput : customInputRef.current;
    targetInput.innerHTML = encodeStr(newMessage, caretPosition);
    setMessage(newMessage);
    setShowUserList(false);
    newCaretPosition = newCaretPosition + mentionedUserName.length + 1;

    setCaretPosition(targetInput, newCaretPosition);
    setCaretPositionState(newCaretPosition);
  };

  const onAtIconClick = () => {
    let caretPosition = caretPositionState;
    let newCaretPosition = caretPosition;
    let lastChar = message.slice(-1);
    let newMessage = message;
    if (lastChar !== '@') {
      if (lastChar === ' ' || lastChar === '' || caretPosition === 0) {
        newMessage = newMessage + '@';
        newCaretPosition = newCaretPosition + 1;
      } else {
        newMessage = newMessage + ' @';
        newCaretPosition = newCaretPosition + 2;
      }
    }
    setMessage(newMessage);
    setCaretPositionState(newCaretPosition);
    customInput.innerHTML = encodeStr(newMessage, caretPosition);
    setShowUserList(prevState => !prevState);
  };

  const handleEnterKey = (e, filteredUsers, caretPosition) => {
    if (e.target.innerText === '') return;

    if (!userListRef.current) {
      handleSubmit(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
      const user = filteredUsers[selectedRowRef?.current];
      handleSelectUser(user, caretPosition, false);
    }
  };

  const handleBackspaceKey = (e, modifiedWord, caretPosition) => {
    if(Array.from(modifiedWord)[0] === '@'){
      let searchedMentionedUser = activeAccount.users.find(user => user?.firstName === modifiedWord.slice(1))
      let isMentionedSomewhereElse = messageRef.current.slice(0,-1).search(searchedMentionedUser?.firstName) !== -1
      if(!isMentionedSomewhereElse){
        let newMentionedUsers = mentionedUsersRef.current.filter(mentionedUser => mentionedUser.userId !== searchedMentionedUser._id)
        setMentionedUsers(newMentionedUsers);
      }
    }
    if (userListRef.current) {
      if (e.target.innerText.charAt(caretPosition - 1) === '@') {
        setShowUserList(false);
      }
    }
    let newCaretPosition = Math.max(caretPosition - 1, 0)

    setCaretPositionState(newCaretPosition);
  };



  const handleAtKey = (e, caretPosition) => {
    const currentText = e.target.innerText;
    if (
      caretPosition === 0 ||
      currentText.charAt(caretPosition - 1) === '' ||
      currentText.charAt(caretPosition - 1).match(/\s+/g)?.length
    ) {
      setShowUserList(true);
    }
    if (!userListRef.current) {
      setCaretPositionState(caretPosition + 1);
    }
  };

  const handleDeleteKey = (e, caretPosition) => {
    if (e.target.innerText.charAt(caretPosition) === '@') {
      setSearch(null);
      setShowUserList(false);
    }
  };

  const handleSpaceKey = (e, filteredUsers, caretPosition) => {
    if (!userListRef.current) {
      setCaretPositionState(caretPosition + 1);
    } else {
      e.preventDefault();
      e.stopPropagation();
      if(filteredUsers.length === 1){
        const user = filteredUsers[0];
        handleSelectUser(user, caretPosition, false);
      }
    }
  };

  const handleTabKey = (e, filteredUsers, caretPosition) => {
    if (userListRef.current) {
      e.preventDefault();
      e.stopPropagation();
      const user = filteredUsers[selectedRowRef?.current];
      handleSelectUser(user, caretPosition, false);
    }
  };

  const handleAlphanumericKey = (modifiedWord, caretPosition, hasFilteredUsers: boolean) => {
    if (!userListRef.current) {
      if(Array.from(modifiedWord)[0] === '@' && hasFilteredUsers){
        setShowUserList(true);
      }
    }
    setCaretPositionState(caretPosition + 1);
  };

  const onCustomInputChange = e => {
    const keyPressed = e.key
    let searchedValue = getSearchedValue(e.target.innerText, keyPressed)
    setIsMentioning(lastCaracterRef.current === '@' || (searchedValue && ![' ', 'Tab'].includes(keyPressed)));
    setLastCaracter(e.key);

    let caretPosition = caretPositionStateRef.current;
    let modifiedWord = getWordAtPosition(e.target.innerText, caretPosition).word;


    setSearch(searchedValue);
    let filteredUsers = getFilteredUsers();
    if(filteredUsers.length === 0){
      setShowUserList(false);
    }
    switch (true) {
      case keyPressed === 'Enter':
        handleEnterKey(e, filteredUsers, caretPosition);
        break;
      case keyPressed === 'Escape':
        setShowUserList(false);
        break;
      case keyPressed === 'Backspace':
        handleBackspaceKey(e, modifiedWord, caretPosition);
        break;
      case keyPressed === '@':
        handleAtKey(e, caretPosition);
        break;
      case keyPressed ==='Delete':
        handleDeleteKey(e, caretPosition);
        break;
      case keyPressed === ' ':
        handleSpaceKey(e, filteredUsers, caretPosition);
        break;
      case keyPressed === 'Tab':
        handleTabKey(e, filteredUsers, caretPosition);
        break;
        //alphanumeric press
      case keyPressed.match(/^[a-zA-Z0-9]$/) !== null:
        handleAlphanumericKey(modifiedWord, caretPosition, filteredUsers.length !== 0);
        break;
      default:
        break;
    }
  };

  const handleCustomDivChange = (e) => {
    let targetNodeArray = Array.from(e.target.childNodes)
    const lastNode:any = targetNodeArray.at(-1);
    if (lastNode.nodeType === Node.ELEMENT_NODE && lastNode.tagName === 'SPAN' && lastNode.innerHTML === '&nbsp;') {
      lastNode.remove();
    }

    // Capture and clean innerText
    let cleanText = e.target.innerText.replace(/\u00A0$/, '').trimEnd();
    // Sauvegarder la position du curseur
    let sel = document.getSelection();
    let range = sel.getRangeAt(0);
    let clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(e.target);
    clonedRange.setEnd(range.endContainer, range.endOffset);

    const clonedRangeString = clonedRange.toString();
    const cursorPosition = clonedRangeString.length;
    // Calcul des espaces avant le curseur
    let beforeCaretSpaces = 0;
    for (let node of clonedRange.cloneContents().childNodes) {
      if (/\s/.test(node.textContent)) {
        beforeCaretSpaces += node.textContent.split(/\s/).length - 1;
      }
      if (node.textContent === '') {
        beforeCaretSpaces -= 1;
      }
    }
    beforeCaretSpaces += clonedRange.cloneContents().childNodes.length - 1;

    let focusNodeSpaces =
      sel.focusNode.textContent.split(/\s/).length - (sel.focusNode.textContent.endsWith('\xA0') ? 2 : 1);
    let modifiedCursorPosition =
      Math.max(
        lastCaracterRef.current === 'Enter' ?
          caretPositionStateRef.current
        : cursorPosition -
        (/\s/.test(clonedRangeString) ? clonedRangeString.split(/\s/).length - 1 : 0) +
        beforeCaretSpaces +
        (/\s/.test(clonedRangeString) ? 0 : focusNodeSpaces),
        0);
    let removeLastSpace = () => {
      if(isMentioningRef.current) return true;
      if(lastCaracterRef.current === 'Backspace'){
        if(modifiedCursorPosition === 0 ||modifiedCursorPosition === cleanText.length){
          return true;
        }
      }
      return false;
    }
    if(cleanText.length !== 0){
      e.target.innerHTML = encodeStr(e.target.innerText, modifiedCursorPosition, removeLastSpace()) || '';
      setCaretPosition(e.target, modifiedCursorPosition);
      setCaretPositionState(modifiedCursorPosition);
      setMessage(e.target.innerText);
    } else {
      e.target.innerHTML = ''
      setMessage('')
    }
  };


  const handleUserListContainerEvent = e => {
    let filteredUsers = getFilteredUsers();
    if (!userListRef.current) {
      return;
    }
    let newSelectedRowIndex = 0;
    let caretPosition = caretPositionStateRef.current;
    switch (e.key) {
      case 'ArrowDown':
      case 'Tab':
        newSelectedRowIndex = selectedRowRef.current === filteredUsers.length ? 0 : selectedRowRef.current + 1;
        break;
      case 'ArrowUp':
        newSelectedRowIndex = selectedRowRef.current === 0 ? filteredUsers.length : selectedRowRef.current - 1;
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        let user = filteredUsers[selectedRowRef?.current];
        handleSelectUser(user, caretPosition, false);
        break;
    }
    selectedRowRef.current = newSelectedRowIndex;
    let rowOffset = (newSelectedRowIndex - 5) * 29;
    let spanOffset = 16;
    let totalOffset = Math.max(rowOffset + spanOffset, 0);
    userListSliderRef.current.scrollTop = totalOffset;
    setSelectedRowState(newSelectedRowIndex);
  };

  const onUserRowHover = idx => {
    selectedRowRef.current = idx;
    setSelectedRowState(idx);
  };

  const onClickOutside = e => {
    const element = e.target;
    if (tooltipRef.current && tooltipRef.current.contains(element)) {
      return;
    }
    if (userListRef.current && !userListRef.current.contains(element)) {
      e.preventDefault();
      e.stopPropagation();
      setShowUserList(false);
    }
  };

  const isRowSelected = index => {
    return selectedRowRef.current === index;
  };

  const getLegalOfficerAccount = userName => allAccounts.find(account => account.companyName === userName);
  const legalOfficerAccount = getLegalOfficerAccount(activeAccount.legalOfficer.name);

  const sanitizeWord = (word) => {
    return word.replace(/^\u00a0/, '');
  }

  const encodeStr = (str, cursorPosition, removeLastSpace: boolean = false) => {
    if (!str) return;

    let { word: modifiedWord, wordIndex: modifiedWordIndex } = getWordAtPosition(str, cursorPosition);
    let spaces = 0;

    let encodedStr = str
      .replace(/\n/g, ' ')
      .split(' ')
      .map((word, idx) => {
        if( word.trim().length === 0){
          spaces += 1;
        }
        let sanitizedWord = sanitizeWord(word);
        let isActiveUser = word.trim() === `@${user.firstName}` || word === `@${user.name}`;
        const isCurrentWord = word.trim() === modifiedWord && idx === (modifiedWordIndex + spaces);
        if (word.trim().startsWith('@')) {
          if (word.length > 1 && isCurrentWord) {
            const className = isActiveUser ? 'last-class-at-activeUser' : 'last-class-at';
            if (removeLastSpace) {
              return `<span class="${className}">${sanitizedWord}</span>`;
            }
            return `<span class="${className}">${sanitizedWord}</span><br/>`;
          } else {
            const className = isActiveUser ? 'class-at-activeUser' : 'class-at';
            return `<span class="${className}">${sanitizedWord}</span>`;
          }
        } else {
          return `<span>${word}</span>`;
        }
      })
      .join('');
    return encodedStr;
  };

  const setCaretPosition = (contentEditableElement, position) => {
    if (!contentEditableElement?.childNodes) return;

    const range = document.createRange();
    const selection = window.getSelection();
    let remaining = position;
    // Parcourir les nœuds enfants pour trouver la position exacte
    for (const node of contentEditableElement.childNodes) {
      const length = node.textContent?.length || 0;
      if (remaining <= length) {
        // Si le caret doit être dans ce nœud
        if (node.nodeType === Node.TEXT_NODE) {
          range.setStart(node, remaining);
          range.setEnd(node, remaining);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.childNodes.length === 1 && node.innerHTML !== '&nbsp;') {
            range.setStart(node.firstChild || node, Math.min(remaining, length));
            range.setEnd(node.firstChild || node, Math.min(remaining, length));
          } else if (node.childNodes.length > 1 && node.innerHTML !== '&nbsp;') {
            // Placer le caret dans le premier enfant texte ou au début du nœud
            const childNode = node.childNodes[0];
            range.setStart(childNode, Math.min(remaining, childNode.textContent?.trimEnd().length || 0));
            range.setEnd(childNode, Math.min(remaining, childNode.textContent?.trimEnd().length || 0));
          } else {
            // Si le nœud est vide, placer après ce nœud
            range.setStartAfter(node);
            range.setEndAfter(node);
          }
        }
        break;
      }

      remaining -= length + 1; // -1 pour l'espace ou séparateur entre nœuds
    }

    // Appliquer la sélection
    selection.removeAllRanges();
    selection.addRange(range);
  };


  const getWordAtPosition = (str, position) => {
    const words = str.split(/\s+/);
    let currentPosition = 0;
    let wordIndex = 0;
    for (const word of words) {
      if (position >= currentPosition && position <= currentPosition + word.length) {
        return { word, wordIndex };
      }
      currentPosition += word.length + 1; // +1 for the space between words
      wordIndex += 1;
    }

    return { word: '', wordIndex: 0 };
  };

  const extractMentions = (str:string) =>  {
    const mentions = [...str.matchAll(/@\w+/g)];
    return mentions || [];
  }

  const getSearchedValue = (str, keyPressed = '') => {
    if (!str) return null;

    const caretPosition = caretPositionStateRef.current;
    const matches = extractMentions(str)
    // Trouver le mot correspondant à la position actuelle du curseur
    const matchedWord = matches.find(match => {
      const startPosition = match.index;
      const endPosition = startPosition + match[0].length;
      return startPosition <= caretPosition && caretPosition <= endPosition;
    });
    if (!matchedWord) return null;

    // Mise à jour du mot trouvé en fonction de `keyPressed`
    let updatedValue = matchedWord[0].slice(1);
    if (keyPressed === 'Backspace' && updatedValue.length > 0) {
      const positionInWord = caretPosition - matchedWord.index;
      updatedValue =
        updatedValue.slice(0, positionInWord - 1) + updatedValue.slice(positionInWord);
    } else if (keyPressed && keyPressed.length === 1) {
      updatedValue += keyPressed;
    }
    return updatedValue.trim() || null;
  };


  //USE EFFECTS
  useEffect(() => {
    updateSeenStatus();
  }, []);

  useEffect(() => {
    tippy(tooltipRef.current, {
      content:
        '<span style="background-color: rgba(221, 241, 249, 1);color: rgba(50, 108, 130, 1);border-radius: 7px;">@mentionner</span> un collaborateur ou votre juriste',
      arrow: true,
      placement: 'top-end',
      theme: 'light',
      allowHTML: true,
      maxWidth: 200,
      offset: [0, 30],
      interactiveBorder: 0,
    });

    const customInput = document.querySelector('#customInput');
    if (customInput) {
      customInput.addEventListener('keydown', onCustomInputChange);
    }
    document.addEventListener('keydown', handleUserListContainerEvent);
    document.body.addEventListener('click', onClickOutside);
    return () => {
      if (customInput) {
        customInput.removeEventListener('keydown', onCustomInputChange);
      }
      document.removeEventListener('keydown', handleUserListContainerEvent);
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  useEffect(() => {
  }, [showUserList]);

  useEffect(() => {
    const sortedMessages = [...messages].sort((a, b) => dayjs(a.time).unix() - dayjs(b.time).unix());
    setSortedMessages(sortedMessages);
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [sortedMessages]);

  return (
    <ChatContainer>
      <MessageListContainer ref={messageListRef}>
        <MessageList isLoading={isLoading} messages={sortedMessages} user={user} deleteMessage={handleDeleteMessage} />
      </MessageListContainer>

      {!admin && (
        <>
          <MessageFooter onSubmit={handleSubmit}>
            <InputContainer>
              {showUserList && (
                <UserListGlobalContainer ref={userListRef} caretPosition={caretPositionState} visibleRows={Math.min(getFilteredUsers()?.length + 1, 6)}>
                  <UserListSlider ref={userListSliderRef}>
                    <span>Collaborateurs</span>
                    <UserListContainer id={'userListContainer'} ref={userListContainerRef}>
                      {getFilteredUsers()?.map(
                        (user, index) =>
                          user && (
                            <UserRow
                              onClick={() => handleSelectUser(user, caretPositionState, false)}
                              key={index}
                              tabIndex={index}
                              selected={isRowSelected(index)}
                              onMouseEnter={() => onUserRowHover(index)}>
                              <Avatar {...stringAvatar(getUserNameById(user._id)?.toLocaleUpperCase(), user._id)} />
                              <UserItem> {user?.firstName ? user?.name + ' ' + user?.firstName : user?.name || user?.email}</UserItem>
                            </UserRow>
                          )
                      )}
                    </UserListContainer>
                  </UserListSlider>
                  <Divider />
                  <span>Mon Juriste</span>
                  <UserRow
                    onClick={() =>
                      handleSelectUser(
                        legalOfficerAccount.users.find(user => user.accountOwner),
                        caretPositionState,
                        true
                      )
                    }
                    key={getFilteredUsers().length}
                    tabIndex={getFilteredUsers().length}
                    selected={isRowSelected(getFilteredUsers().length)}
                    onMouseEnter={() => onUserRowHover(getFilteredUsers().length)}>
                    <Avatar {...stringAvatar(activeAccount.legalOfficer.name?.toLocaleUpperCase(), legalOfficerAccount._id)} />
                    <UserItem> {activeAccount.legalOfficer.name || activeAccount.legalOfficer?.email}</UserItem>
                  </UserRow>
                </UserListGlobalContainer>
              )}
              <IconContainer>
                <OnAtIcon ref={tooltipRef} onClick={onAtIconClick} fill={showUserList ? '#3D3D3D' : '#C4C3C2'} /> <Line></Line>
                <SubmitIcon
                  pointerEvents={message.trim() === '' ? 'none' : 'all'}
                  cursor={message.trim() === '' ? 'auto' : 'pointer'}
                  onClick={handleSubmit}
                  strokeColor={message !== '' ? '#3D3D3D' : '#C4C3C2'}
                />
              </IconContainer>
              <CustomDivContainer>
                <CustomDiv
                  onInput={handleCustomDivChange}
                  contentEditable='true'
                  ref={customInputRef}
                  id='customInput'
                  data-text={`${t('chat.leave_comment_placeholder')}`}
                />
              </CustomDivContainer>
            </InputContainer>
          </MessageFooter>
        </>
      )}
    </ChatContainer>
  );
};

export default Chat;
